<template>
  <div class="grid gap-8">
    <div class="col-span-full space-y-2"
         v-for="(product, index) in products" :key="index">
      <div @click="setActiveHosting(index)" @focus="setActiveHosting(index)"
           class="pt-6 sm:pt-0 bg-white cursor-pointer shadow-md mt-3 flex relative z-30 hover:ring-4 hover:ring-brand-blue-light-700 hover:ring-offset-0 hover:ring-inset focus:outline-none focus:ring-4 focus:ring-brand-blue-light-700 focus:ring-offset-0 transition ease-in-out duration-150 cursor-pointer"
           :class="{'ring-4 ring-offset-0 ring-inset ring-brand-blue-light-700': showActiveHosting === index}">
        <div class="w-1.5 h-auto bg-brand-blue-light-700"></div>
        <div :tabindex="index"
             class="w-full flex flex-col items-center md:flex-row md:items-center justify-between p-6">
          <div class="flex flex-col md:flex-row">
            <div
                class="inline-flex self-center justify-center bg-brand-blue-lightest-100 shadow-md md:mr-8 -mt-14 md:-mt-0">
              <span
                  class="flex flex-col items-center justify-center text-3xl font-bold h-16 w-16 bg-gradient-to-t from-brand-blue to-brand-blue-light text-white">{{
                  product.initial
                }}</span>
            </div>
            <div class="mt-8 items-center md:mt-0 md:items-baseline flex flex-col md:block md:w-4/5 lg:w-auto">
              <div class="flex items-center">
                <h2 class="font-bold text-2xl w-full">{{ product.title }}</h2>
                <div v-if="product.bestseller" class="w-full relative flex items-center">
                  <div class="shadow-md bg-gradient-to-t from-brand-blue to-brand-blue-light text-white px-3 py-2 font-bold">Unser Bestseller</div>
                </div>
              </div>
              <p class="mt-2 text-md text-center md:text-left">{{ product.subtitle }}</p>
            </div>
          </div>
          <div class="flex flex-col items-center md:items-end justify-end md:w-2/5 lg:w-auto"><span
              class="text-2xl font-bold text-brand-orange">{{ product.currency }} {{ product.price }}</span>
            <span class="block font-light text-right text-gray-600"><span class="font-bold">*</span><span
                class="font-medium">Monatlich</span></span>
          </div>
        </div>
      </div>
      <div class="col-span-full shadow-md bg-white p-6" v-if="showActiveHosting === index">
        <div>
          <div class="grid gap-6">
            <div v-for="(detail, index1) in product.extra_attributes" :key="index1">
              <h3 class="font-bold text-lg">{{ detail.section_title }}</h3>
              <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div v-for="(item, index) in detail.options" :key="index" :id="'detail' + index">
                  <div
                      class="flex flex-col items-center bg-brand-blue-light-700 text-white p-4"><span
                      class="text-xl font-bold" :class="{'text-brand-orange' :item.status === true}">{{
                      item.value
                    }}</span><span
                      class="font-normal">{{
                      item.label
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HostingList',
  data() {
    return {
      showActiveHosting: null,
      products         : [],
    };
  },
  mounted() {
    this.loadProducts();
  },
  methods: {
    setActiveHosting(index) {
      if (this.showActiveHosting === index) {
        return this.showActiveHosting = null;
      } else {
        return this.showActiveHosting = index;
      }
    },
    loadProducts() {
      axios.get('products').then((data) => {
        this.products = data.data.products;
      });
    },
  },
};
</script>
