<template>
  <div class="mt-6 max-w-screen-lg mx-auto">
    <div v-if="form.errors.has('errorMessages')" class="bg-red-50 border-l-4 border-red-400 p-4 mb-10 shadow-sm">
      <div class="flex">
        <div class="flex-shrink-0 self-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 64 64" stroke="currentColor"
               aria-hidden="true" class="h-8 w-8 text-red-400">
            <g>
              <path d="M61.1,42L38.9,11.8c-1.6-2.2-4.2-3.5-6.9-3.5c-2.8,0-5.3,1.3-6.9,3.5L2.9,42c-1.9,2.6-2.2,6.1-0.7,9
                        c1.5,2.9,4.4,4.7,7.7,4.7h44.3c3.3,0,6.2-1.8,7.7-4.7S63,44.6,61.1,42z M58.7,49.4c-0.9,1.7-2.6,2.8-4.5,2.8H9.9
                        c-2,0-3.7-1-4.5-2.8c-0.9-1.7-0.7-3.7,0.4-5.3l22.1-30.2c1-1.3,2.5-2.1,4.1-2.1c1.6,0,3.1,0.8,4.1,2.1l22.1,30.2
                        C59.4,45.7,59.6,47.6,58.7,49.4z"/>
              <path
                  d="M32,38c1,0,1.8-0.8,1.8-1.8V25c0-1-0.8-1.8-1.8-1.8S30.2,24,30.2,25v11.3C30.2,37.2,31,38,32,38z"/>
              <path
                  d="M32,41.3c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9C34.9,42.6,33.6,41.3,32,41.3z"/>
            </g>
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-red-400" v-html="form.errors.get('errorMessages')"></p>
        </div>
      </div>
    </div>
    <div v-if="!success" class="space-y-10">
      <div v-if="!form.errors.has('errorMessages')"
           class="bg-brand-orange-50 border-l-4 border-brand-orange-400 p-4 shadow-sm">
        <div class="flex">
          <div class="flex-shrink-0 self-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 64 64" stroke="currentColor"
                 aria-hidden="true" class="h-8 w-8 text-brand-orange-dark">
              <g>
                <path d="M61.1,42L38.9,11.8c-1.6-2.2-4.2-3.5-6.9-3.5c-2.8,0-5.3,1.3-6.9,3.5L2.9,42c-1.9,2.6-2.2,6.1-0.7,9
                        c1.5,2.9,4.4,4.7,7.7,4.7h44.3c3.3,0,6.2-1.8,7.7-4.7S63,44.6,61.1,42z M58.7,49.4c-0.9,1.7-2.6,2.8-4.5,2.8H9.9
                        c-2,0-3.7-1-4.5-2.8c-0.9-1.7-0.7-3.7,0.4-5.3l22.1-30.2c1-1.3,2.5-2.1,4.1-2.1c1.6,0,3.1,0.8,4.1,2.1l22.1,30.2
                        C59.4,45.7,59.6,47.6,58.7,49.4z"/>
                <path
                    d="M32,38c1,0,1.8-0.8,1.8-1.8V25c0-1-0.8-1.8-1.8-1.8S30.2,24,30.2,25v11.3C30.2,37.2,31,38,32,38z"/>
                <path
                    d="M32,41.3c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9C34.9,42.6,33.6,41.3,32,41.3z"/>
              </g>
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm text-brand-orange-dark">
              Dein <strong>Bestätigungs-Link</strong> ist abgelaufen. Um einen neuen Bestätigungs-Link anzufordern,
              musst
              Du im unteren Feld deine E-Mail-Adresse angeben, welche Du bei der Registrierung angegeben hast.
            </p>
          </div>
        </div>
      </div>
      <div class="bg-white px-4 py-5 shadow sm:p-6">
        <div class="grid grid-cols-1 gap-4">
          <div class="col-span-1">
            <label for="first_name"
                   class="block text-sm font-bold"
                   :class="{'text-red-500': form.errors.has('first_name'), 'text-brand-black': ''}">Vorname<span
                class="text-red-600">*</span></label>
            <div class="right-0 flex flex-col w-full relative">
              <div class="mt-1 relative flex items-center">
                <input type="text" name="first_name" id="first_name"
                       placeholder="Vorname"
                       class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                       :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('first_name')}"
                       v-model="form.first_name">
              </div>
              <span role="alert" class="mt-2 text-sm text-red-500"
                    v-if="form.errors.has('first_name')"
                    v-text="form.errors.get('first_name')"></span>
            </div>
          </div>
          <div class="col-span-1">
            <label for="email"
                   class="block text-sm font-bold"
                   :class="{'text-red-500': form.errors.has('email'), 'text-brand-black': ''}">E-Mail-Adresse<span
                class="text-red-600">*</span></label>
            <div class="right-0 flex flex-col w-full relative">
              <div class="mt-1 relative flex items-center">
                <input type="text" name="email" id="email"
                       placeholder="E-Mail-Adresse"
                       class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                       :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('email')}"
                       v-model="form.email">
              </div>
              <span role="alert" class="mt-2 text-sm text-red-500"
                    v-if="form.errors.has('email')"
                    v-text="form.errors.get('email')"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button type="button"
                class="flex self-start items-center py-2 px-5 font-bold text-center text-white rounded-sm text-base bg-brand-orange hover:bg-brand-orange-dark hover:cursor-pointer whitespace-nowrap focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 focus-visible:rounded-sm focus:outline-none"
                :class="{'cursor-not-allowed': loaderSpinner}"
                @click="sendNewVerifyLink" :disabled="loaderSpinner">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24" v-if="loaderSpinner">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Neuen Bestätigungs-Link anfordern
        </button>
      </div>
    </div>
    <div v-if="success" class="flex flex-col items-center">
      <div
          class="bg-brand-orange p-3 text-white text-7xl h-36 w-36 items-center justify-center flex rounded rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 64 64" stroke="currentColor"
             aria-hidden="true" class="w-20 w-20 text-white mr-2">
          <path d="M61.7,13.3c-0.4-0.4-1-0.4-1.4,0L23.8,48.6c-0.6,0.5-1.5,0.5-2.1,0L3.7,31.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4
	l18.1,17.6c0.7,0.6,1.5,1,2.4,1c0.9,0,1.8-0.3,2.4-1l36.5-35.4C62.1,14.3,62.1,13.6,61.7,13.3z"/>
        </svg>
      </div>
      <div class="mt-10 max-w-xl mx-auto text-center">
        <h2 class="text-2xl font-bold text-brand-orange">{{ form.success.success.title }}</h2>
        <p class="mt-2 text-md" v-html="form.success.success.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../classes/FormClass';

export default {
  name : 'ResendVerifyMail',
  props: [],
  data() {
    return {
      form         : new Form({
        email        : null,
        first_name   : null,
        errorMessages: null,
        modelType    : null
      }),
      loaderSpinner: false,
      success      : false
    };
  },
  mounted() {
    this.form.modelType = window.location.search.split('?type=')[1];
  },
  methods: {
    sendNewVerifyLink() {
      /*  this.loaderSpinner = true;*/

      this.form.post('email/resend').then(() => {
        this.success = true;
        console.log(this.form.success.get('title'));
      }).catch((error) => {
        this.loaderSpinner = false;
      }).finally(() => {
        this.loaderSpinner = false;
      });
    }
  }
};
</script>

<style scoped>

</style>
