window._ = require("lodash");
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

let standardProtocol = "https://";
let standardUrl = "api.wdigital.host";

if (process.env.MIX_API_URL !== undefined) {
    standardProtocol = "http://";
    standardUrl = process.env.MIX_API_URL;
}

window.axios.defaults.baseURL = standardProtocol + standardUrl;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Content-Type"] = "application/json";
window.axios.defaults.headers.common["Accept"] = "application/json";
window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common = {
    "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
};
