require('./bootstrap');

// import
import Vue from 'vue';
import VueMask from 'v-mask';

Vue.use(VueMask);

//components
const faqs = Vue.component(
    'faqs-list',
    require('./components/FaqList.vue').default
);
const hostingList = Vue.component(
    'hosting-list',
    require("./components/HostingList.vue").default
);
const productInfoPackage = Vue.component(
    "domain-list",
    require("./components/ProductRequestPackage.vue").default
);
const checkDomainname = Vue.component(
    'check-domainname',
    require('./components/CheckDomainname.vue').default
);
const registerForm = Vue.component(
    'register-form',
    require('./components/RegisterForm.vue').default
);
const testimonials = Vue.component(
    'testimonials',
    require('./components/Testimonials.vue').default
);
const contactForm = Vue.component(
    'contact-form',
    require('./components/ContactForm.vue').default
);
const autoReloadModal = Vue.component('auto-reload-modal', require('./components/AutoReloadModal.vue').default);
const privacyBanner = Vue.component('privacy-banner', require('./components/PrivacyBanner.vue').default);
const resendVerifyMail = Vue.component('resend-verify-mail', require('./components/ResendVerifyMail.vue').default);

// id app
let app = new Vue({
    el        : '#app',
    components: {
        faqs,
        hostingList,
        productInfoPackage,
        checkDomainname,
        registerForm,
        testimonials,
        contactForm,
        autoReloadModal,
        privacyBanner,
        resendVerifyMail
    }
});
const AlertSession = document.getElementById("alert_session");

if (AlertSession !== null) {
    setTimeout(function () {
        AlertSession.remove();
    }, 10000);
}
