<template>
  <div>
    <div v-for="faq in faqs" :key="faq.id" class="my-2 bg-white shadow-md">
      <dt class="text-lg">
        <div
            class="flex w-full cursor-pointer items-center justify-between p-4 text-left transition duration-150 ease-in-out bg-gradient-to-t hover:from-brand-blue hover:to-brand-blue-light hover:text-white hover:text-black"
            :class="{'bg-gradient-to-t from-brand-blue to-brand-blue-light text-white': showDescription === faq.id, 'text-brand-black': ''}"
            aria-controls="faq-0"
            aria-expanded="false"
            @click="selectTab(faq.id)"
        >
          <span class="font-semibold text-md">{{ faq.title }}</span>
          <span class="ml-6 flex h-7 items-center">
                        <svg
                            class="h-6 w-6 rotate-0 transform transition duration-150 ease-in-out"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                            :class="{
                                'rotate-180': showDescription === faq.id,
                            }"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </span>
        </div>
      </dt>
      <dd
          class="py-4 pr-12 pl-4 transition duration-150 ease-in-out"
          v-if="showDescription === faq.id"
      >
        <p
            class="text-base leading-7 text-brand-black"
            v-html="faq.description"
        ></p>
      </dd>
    </div>
  </div>
</template>

<script>
export default {
  name : 'FaqList',
  props: ['faqs'],
  data() {
    return {
      showDescription: false
    };
  },
  methods: {
    selectTab(selectedFaq) {
      if (this.showDescription === selectedFaq) {
        console.log(selectedFaq);
        this.showDescription = null;
      } else {
        this.showDescription = selectedFaq;
      }
    }
  }
};
</script>

<style scoped>
strong {
  @apply text-brand-orange;
}
</style>
