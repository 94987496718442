<template>
  <div class="container grid grid-cols-1 gap-4 md:gap-10 lg:grid-cols-2">
    <div class="col-span-full mx-auto max-w-3xl text-center">
      <div class="mx-auto max-w-3xl text-center">
        <h2 class="text-3xl font-bold tracking-wider sm:text-4xl">
          Das sagen unsere Kunden
        </h2>
      </div>
    </div>
    <div class="col-span-full mt-14 xl:mt-0 grid w-full gap-4 md:grid-cols-2 md:gap-10 lg:grid-cols-3">
      <div class="flex relative bg-white shadow-md" v-for="(testimonial, index) in this.testimonials" :key="index">
        <div class="w-1.5 h-auto bg-brand-blue-light-700"></div>
        <div class="w-full flex flex-col px-6 py-8 xl:py-11 xl:px-10">
          <blockquote class="mb-auto text-base">{{ testimonial.content }}</blockquote>
          <div class="mt-4 flex items-center space-x-0.5 text-brand-orange">
            <svg fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-5 w-5 stroke-2"
                :class="testimonial.stars >= maxStar ? 'fill-current' : 'fill-brand-orange-100'"
                v-for="maxStar in maxStars">
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
              ></path>
            </svg>
          </div>
          <div class="mt-5 flex items-center space-x-4 lg:space-x-6">
                    <span class="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100">
                        <svg
                            class="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                        </svg>
                    </span>
            <div>
              <h3 class="text-base font-bold">
                {{ testimonial.name }}
              </h3>
              <p class="text-grey-500 text-sm">
                Kunde seit {{ testimonial.client_since }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'Testimonials',
  props: [],
  data() {
    return {
      maxStars    : 5,
      testimonials: []
    };
  },
  mounted() {
    this.loadTestimonials();
  },
  methods: {
    loadTestimonials() {
      axios.get('testimonials').then((data) => {
        this.testimonials = data.data.testimonials;
      });
    }
  }
};
</script>

<style scoped></style>
