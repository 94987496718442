<template>
    <div>
        <div class="rounded-md shadow" v-if="showInput === null">
            <button
                type="button"
                class="flex w-full items-center justify-center rounded-md border border-transparent bg-gray-800 px-5 py-3 text-base font-medium text-white hover:bg-gray-900"
                @click="showInputFields(id)"
            >
                Kostenloses Infopaket anfordern
            </button>
        </div>
        <div class="mt-6 space-y-6" v-if="showInput === id && !success">
            <div class="relative">
                <label
                    for="email"
                    class="block text-sm font-bold text-brand-black"
                    >E-Mail-Adresse</label
                >
                <div class="relative right-0 flex w-full flex-col">
                    <div class="relative flex items-center">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="E-Mail-Adresse"
                            class="mt-1 block w-full border border-gray-300 border-gray-300 py-2 px-3 shadow-sm"
                            @blur="checkEmail(fields.email, 'E-Mail')"
                            v-model="fields.email"
                        />
                    </div>
                </div>
                <span
                    role="alert"
                    class="mt-2 text-sm text-red-500"
                    v-if="errors.email"
                    >{{ errors.email[0] }}</span
                >
            </div>
            <div class="relative">
                <label
                    for="first_name"
                    class="block text-sm font-bold text-brand-black"
                    >Vorname</label
                >
                <div class="relative right-0 flex w-full flex-col">
                    <div class="relative flex items-center">
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            placeholder="Vorname"
                            class="mt-1 block w-full border border-gray-300 border-gray-300 py-2 px-3 shadow-sm"
                            v-model="fields.first_name"
                            @blur="
                                checkEmptyField(
                                    'first_name',
                                    fields.first_name,
                                    'Vorname'
                                )
                            "
                        />
                    </div>
                </div>
                <span
                    role="alert"
                    class="mt-2 text-sm text-red-500"
                    v-if="errors.first_name"
                    >{{ errors.first_name[0] }}</span
                >
            </div>
            <div class="relative">
                <label
                    for="last_name"
                    class="block text-sm font-bold text-brand-black"
                    >Nachname</label
                >
                <div class="relative right-0 flex w-full flex-col">
                    <div class="relative flex items-center">
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="Nachname"
                            class="mt-1 block w-full border border-gray-300 border-gray-300 py-2 px-3 shadow-sm"
                            v-model="fields.last_name"
                        />
                    </div>
                </div>
                <span
                    role="alert"
                    class="mt-2 text-sm text-red-500"
                    v-if="errors.last_name"
                    >{{ errors.last_name[0] }}</span
                >
            </div>
            <div class="flex justify-end">
                <button
                    type="button"
                    class="hover:bg-brand-blue-dark-900 focus:outline-none flex items-center justify-center border border-transparent bg-brand-blue px-5 py-3 text-sm font-bold text-white transition duration-150 ease-in-out focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="sendRequest"
                >
                    <svg
                        class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        v-if="loaderSpinner === true"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    Jetzt anfordern
                </button>
            </div>
        </div>
        <div v-if="success">
            <div class="rounded-md bg-green-50 p-4">
                <div class="flex">
                    <div class="ml-3">
                        <div class="mt-2 text-sm text-green-700">
                            <p>
                                {{ successData }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductRequestPackage",
    props: ["id", "title"],
    data() {
        return {
            showInput: null,
            fields: {
                email: "",
                first_name: "",
                last_name: "",
                packageTitle: this.title,
            },
            errors: {
                errorMessage: {},
            },
            loaderSpinner: false,
            success: false,
            successData: null,
        };
    },
    methods: {
        sendRequest() {
            this.loaderSpinner = true;

            let apiEndpoint = "product-info";

            axios.get("/sanctum/csrf-cookie").then((response) => {
                if (response.status === 204) {
                    axios
                        .post(apiEndpoint, this.fields)
                        .then(
                            function (response) {
                                if (response.status === 200) {
                                    console.log(response.data.data);
                                    this.success = true;
                                    this.successData = response.data.data.msg;
                                }
                            }.bind(this)
                        )
                        .catch(
                            function (error) {
                                console.log(error.response.data);
                                this.errors = error.response.data.errorMessage;
                            }.bind(this)
                        )
                        .finally(
                            function () {
                                this.loaderSpinner = false;
                            }.bind(this)
                        );
                }
            });
        },
        showInputFields(productId) {
            if (this.showInput === productId) {
                console.log(productId);
                this.showInput = null;
            } else {
                this.showInput = productId;
            }
        },
        validEmail(email) {
            const regex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(email);
        },
        checkEmail(email) {
            console.log(this.validEmail(email));
            if (!this.validEmail(email)) {
                this.errors.email =
                    "Bitte gebe eine gültige E-Mail-Adresse ein.";
            } else {
                this.errors.email = "";
            }
        },
        checkEmptyField(key, value, fieldName = "") {
            if (value === "" || value === false) {
                this.errors[key] =
                    "Das Feld " + fieldName + " darf nicht leer sein.";
            } else {
                this.$delete(this.errors, key);
                //  this.errors[key] = '';
            }
        },
    },
};
</script>

<style scoped></style>
