<template>
  <div>
    <div id="cookieConsent" class="fixed left-0 right-0 bottom-0 bg-white p-5 z-40 shadow-lg">
      <div class="max-w-7xl mx-auto">
        <strong class="font-bold underline">Hinweise zum Datenschutz:</strong>
        <p class="text-sm">Diese Website benutzt Cookies. Bevor Du weitere Seiten auf dieser Website besuchst, bitten
          wir Dich, unsere Bestimmungen zum Datenschutz, welche Du unter dem Button "Datenschutz" öffnen kannst, zu
          lesen. Bei Klick auf "Akzeptieren" bestätigst Du, dass Du die Bestimmungen gelesen hast und diese
          akzeptierst.
          <a class="link" href="/datenschutz" target="_blank">Datenschutzerklärung</a>
        </p>
      </div>
      <div class="float-right text-sm">
        <button type="button" @click="allowsCookie"
                class="px-5 py-1 mt-4 mr-1 font-bold border-2 border-brand-orange text-center text-white rounded-sm md:max-w-xl md:w-auto bg-brand-orange hover:bg-brand-orange-dark hover:border-brand-orange-dark hover:cursor-pointer focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 focus-visible:rounded-sm focus:outline-none">
          Akzeptieren
        </button>
        <button type="button" @click="disabledCookie"
                class="px-5 py-1 mt-4 mr-1 bg-white border-2 border-brand-orange text-center text-brand-black rounded-sm md:max-w-xl md:w-auto hover:bg-brand-orange-dark hover:border-brand-orange-dark hover:text-white hover:cursor-pointer focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 focus-visible:rounded-sm focus:outline-none">
          Nein, danke
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'PrivacyBanner',
  props: [],
  data() {
    return {};
  },
  methods: {
    allowsCookie() {
      axios.get('allows-cookie').then((data) => {
        this.removeCookieConsent();
      });
    },
    disabledCookie() {
      axios.get('disabled-cookie').then((data) => {
        this.removeCookieConsent();
      });
    },
    removeCookieConsent() {
      document.getElementById('cookieConsent').remove();
    }
  }
};
</script>

<style scoped>

</style>
