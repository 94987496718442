<template>
  <div>
    <div v-if="!success" class="text-lg max-w-screen-lg mx-auto">
      <h1><span class="block text-3xl text-center leading-8 font-extrabold tracking-wieder sm:text-4xl">Kontakt</span>
      </h1>
      <p class="mt-8 text-md text-gray-500 leading-8"></p>
    </div>
    <div class="mt-6 max-w-screen-lg mx-auto">
      <div id="form" v-if="!success">
        <div class="space-y-10">
          <div class="bg-white px-4 py-5 shadow sm:p-6">
            <div class="grid grid-cols-1 gap-4">
              <div class="md:col-span-1">
                <h3 class="text-lg font-bold leading-6 text-gray-900">Deine Daten</h3>
              </div>
              <div class="grid grid-cols-12 gap-4 md:mt-0">
                <div class="relative col-span-12 sm:col-span-6">
                  <label for="contact_type" class="block text-sm font-bold text-black"
                         :class="{'text-red-500':form.errors.has('contact_type'),'text-black': '',}">Kontakttype<span
                      class="text-red-600">*</span></label>
                  <div class="relative flex items-center">
                    <select
                        id="contact_type"
                        name="contact_type"
                        autocomplete="contact_type"
                        class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                        :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-900 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('contact_type')}"
                        v-model="form.contact_type">
                      <option value="please_select">Bitte wählen</option>
                      <option value="business">Business</option>
                      <option value="private">Privat</option>
                    </select>
                  </div>
                  <span
                      role="alert"
                      class="mt-2 text-sm text-red-500"
                      v-if="form.errors.has('contact_type')"
                      v-text="form.errors.get('contact_type')"
                  ></span>
                </div>
                <div class="relative col-span-12 sm:col-span-6" v-if="form.contact_type === 'business'">
                  <label for="company_name" class="block text-sm font-bold text-black"
                         :class="{'text-red-500':form.errors.has('company_name'),'text-black': '',}">Firmennamen<span
                      class="text-red-600">*</span></label>
                  <div class="right-0 flex w-full flex-col">
                    <div class="relative flex items-center">
                      <input
                          type="text"
                          name="company_name"
                          id="company_name"
                          placeholder="Firmennamen"
                          class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                          :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-900 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('company_name'),}"
                          v-model="form.company_name"/>
                    </div>
                    <span role="alert" class="mt-2 text-sm text-red-500" v-if="form.errors.has('company_name')"
                          v-text="form.errors.get('company_name')"></span>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="relative col-span-12 sm:col-span-6">
                  <label for="salutation" class="block text-sm font-bold text-black"
                         :class="{'text-red-500':form.errors.has('salutation'),'text-black': '',}">Anrede<span
                      class="text-red-600">*</span></label>
                  <div class="relative flex items-center">
                    <select
                        id="salutation"
                        name="salutation"
                        autocomplete="salutation"
                        class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                        :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('salutation')}"
                        v-model="form.salutation">
                      <option value="please_select">
                        Bitte wählen
                      </option>
                      <option value="female">Frau</option>
                      <option value="male">Herr</option>
                    </select>
                  </div>
                  <span
                      role="alert"
                      class="mt-2 text-sm text-red-500"
                      v-if="form.errors.has('salutation')"
                      v-text="form.errors.get('salutation')"
                  ></span>
                </div>
              </div>
              <div class="md:col-span-1">
                <div class="grid gap-4 sm:grid-cols-1">
                  <div class="grid md:grid-cols-2 gap-4">
                    <div class="col-span-1">
                      <label for="firstName"
                             class="block text-sm font-bold"
                             :class="{'text-red-500': form.errors.has('firstName'), 'text-brand-black': ''}">Vorname<span
                          class="text-red-600">*</span></label>
                      <div class="right-0 flex flex-col w-full relative">
                        <div class="mt-1 relative flex items-center">
                          <input type="text" name="firstName" id="firstName"
                                 placeholder="Vorname"
                                 class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                                 :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('firstName')}"
                                 v-model="form.firstName">
                        </div>
                        <span role="alert" class="mt-2 text-sm text-red-500"
                              v-if="form.errors.has('firstName')"
                              v-text="form.errors.get('firstName')"></span>
                      </div>
                    </div>
                    <div class="md:col-span-1">
                      <label for="lastName"
                             class="block text-sm font-bold"
                             :class="{'text-red-500': form.errors.has('lastName'), 'text-brand-black': ''}">Nachname<span
                          class="text-red-600">*</span></label>
                      <div class="right-0 flex flex-col w-full relative">
                        <div class="mt-1 relative flex items-center">
                          <input type="text" name="lastName" id="lastName"
                                 placeholder="Nachname"
                                 class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                                 :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('lastName')}"
                                 v-model="form.lastName">
                        </div>
                        <span role="alert" class="mt-2 text-sm text-red-500"
                              v-if="form.errors.has('lastName')"
                              v-text="form.errors.get('lastName')"></span>
                      </div>
                    </div>
                  </div>
                  <div class="grid md:grid-cols-2 gap-4">
                    <div class="col-span-1">
                      <label for="email"
                             class="block text-sm font-bold"
                             :class="{'text-red-500': form.errors.has('email'), 'text-brand-black': ''}">E-Mail
                        Adresse<span
                            class="text-red-600">*</span></label>
                      <div class="right-0 flex flex-col w-full relative">
                        <div class="mt-1 relative flex items-center">
                          <input type="email" name="email" id="email"
                                 placeholder="E-Mail Adresse"
                                 class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                                 :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('email')}"
                                 v-model="form.email">
                        </div>
                        <span role="alert" class="mt-2 text-sm text-red-500"
                              v-if="form.errors.has('email')"
                              v-text="form.errors.get('email')"></span>
                      </div>
                    </div>
                    <div class="col-span-1">
                      <label for="phoneNr"
                             class="block text-sm font-bold flex justify-between"
                             :class="{'text-red-500': form.errors.has('phoneNr'), 'text-brand-black': ''}">Telefon
                        Nr.<span
                            id="phoneNr-optional" class="text-sm text-gray-500 font-normal">Optional</span></label>
                      <div class="right-0 flex flex-col w-full relative">
                        <div class="mt-1 relative flex items-center">
                          <input type="text" name="phoneNr" id="phoneNr"
                                 placeholder="Telefon Nr."
                                 class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                                 :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('phoneNr')}"
                                 v-model="form.phoneNr" v-mask="'\+\XX-XX-XXXXXXXXXX'"
                                 :placeholder="'4158975588897'|VMask('\+\XX-XX-XXXXXXXXXX')">
                        </div>
                        <span role="alert" class="mt-2 text-sm text-red-500"
                              v-if="form.errors.has('phoneNr')"
                              v-text="form.errors.get('phoneNr')"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white px-4 py-5 shadow sm:p-6">
            <div class="grid grid-cols-1 gap-4">
              <div class="md:col-span-1">
                <h3 class="text-lg font-bold leading-6 text-gray-900">Deine Anfrage</h3>
              </div>
              <div class="col-span-1">
                <label for="subject"
                       class="block text-sm font-bold"
                       :class="{'text-red-500': form.errors.has('subject'), 'text-brand-black': ''}">Betreff<span
                    class="text-red-600">*</span></label>
                <div class="right-0 flex flex-col w-full relative">
                  <div class="mt-1 relative flex items-center">
                    <input type="text" name="subject" id="subject"
                           placeholder="Betreff"
                           class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                           :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('subject')}"
                           v-model="form.subject">
                  </div>
                  <span role="alert" class="mt-2 text-sm text-red-500"
                        v-if="form.errors.has('subject')"
                        v-text="form.errors.get('subject')"></span>
                </div>
              </div>
              <div class="col-span-1">
                <label for="message"
                       class="block text-sm font-bold"
                       :class="{'text-red-500': form.errors.has('message'), 'text-brand-black': ''}">Dein
                  Anliegen<span class="text-red-600">*</span></label>
                <div class="right-0 flex flex-col w-full relative">
                  <div class="mt-1 relative flex items-center">
                  <textarea name="message" id="message" cols="20"
                            rows="5"
                            class="shadow-sm focus:ring-brand-orange focus:border-brand-orange block w-full sm:text-sm border-gray-300"
                            :class="{'invalid ring-1 ring-red-500 focus:ring-red-500 border-red-300 text-red-500 placeholder-red-500': form.errors.has('message')}"
                            placeholder="Beschreibe dein Anliegen..." v-model="form.message"></textarea>
                  </div>
                  <span role="alert" class="mt-2 text-sm text-red-500"
                        v-if="form.errors.has('message')"
                        v-text="form.errors.get('message')"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white px-4 py-5 shadow sm:p-6">
            <div class="md:grid md:grid-cols-1 md:gap-6">
              <div class="md:col-span-1">
                <h3 class="text-lg font-bold leading-6 text-gray-900">Benachrichtigungen</h3>
              </div>
              <div class="mt-5 md:col-span-2 md:mt-0">
                <div class="col-span-2 grid grid-cols-12 divide-y divide-gray-200">
                  <div class="relative col-span-full pb-4">
                    <div class="grid-cols-full grid"><span
                        class="text-base font-bold text-gray-900">E-Mail Marketing</span>
                      <ul class="mt-4">
                        <li>
                          <div class="flex items-center justify-between">
                                                  <span class="flex flex-grow flex-col">
                                                      <span class="block text-sm font-bold text-black"
                                                            id="availability-label">WDigital Newsletter abonnieren</span>
                                                      <span class="text-sm text-gray-500 md:w-3/4">Erhalte regelmässig informationen zu unseren Dienstleistungen, Produkte, Tipps & Tricks zu verschiedenen Themen. Und bleibe so immer auf dem aktuellsten Stand.</span>
                                                  </span>
                            <button type="button" @click="selectNewsletterToggle"
                                    class="focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-brand-orange focus:ring-offset-2"
                                    :class="{'bg-brand-orange':form.newsletter_subscription === true, 'bg-gray-200': '',}"
                                    role="switch"><span aria-hidden="true"
                                                        class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                                        :class="{'translate-x-5':form.newsletter_subscription ===true,'translate-x-0':'',}"></span>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="relative col-span-full bg-white px-4 py-5 shadow sm:p-6">
            <div class="right-0 flex w-full flex-col">
              <div class="relative flex">
                <input
                    type="checkbox"
                    name="data_privacy"
                    id="data_privacy"
                    class="shadow-sm focus:ring-brand-orange focus:border-brand-orange border-gray-300 mt-1 block h-5 w-5 border border-gray-300"
                    :class="{'border-red-300 border-2':form.errors.has('data_privacy'), 'text-black': '',}"
                    v-model="form.data_privacy"
                />
                <label
                    for="data_privacy"
                    class="prose-blog ml-2 block text-xs font-medium text-black">Hiermit bestätige ich,
                  dass ich die <a class="link" href="/datenschutz" target="blank">Datenschutzerklärung</a> von
                  Würtenberger Digital gelesen habe und mit der Geltung einverstanden bin.<span
                      class="text-red-600">*</span></label>
              </div>
              <span
                  role="alert"
                  class="ml-7 text-sm text-red-500"
                  v-if="form.errors.has('data_privacy')"
                  v-text="form.errors.get('data_privacy')"
              ></span
              >
            </div>
          </div>
          <div class="flex justify-end">
            <button type="button"
                    class="flex self-start items-center py-2 px-5 font-bold text-center text-white rounded-sm md:max-w-xs md:w-auto text-base bg-brand-orange hover:bg-brand-orange-dark hover:cursor-pointer whitespace-nowrap focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 focus-visible:rounded-sm focus:outline-none"
                    :class="{'cursor-not-allowed': loaderSpinner}"
                    @click="saveContactData" :disabled="loaderSpinner">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" v-if="loaderSpinner">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Nachricht absenden
            </button>
          </div>
        </div>
      </div>
      <div v-if="success" class="flex flex-col items-center">
        <div
            class="bg-brand-orange p-3 text-white text-7xl h-36 w-36 items-center justify-center flex rounded rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 64 64" stroke="currentColor"
               aria-hidden="true" class="w-20 w-20 text-white mr-2">
            <path d="M61.7,13.3c-0.4-0.4-1-0.4-1.4,0L23.8,48.6c-0.6,0.5-1.5,0.5-2.1,0L3.7,31.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4
	l18.1,17.6c0.7,0.6,1.5,1,2.4,1c0.9,0,1.8-0.3,2.4-1l36.5-35.4C62.1,14.3,62.1,13.6,61.7,13.3z"/>
          </svg>
        </div>
        <div class="mt-10 max-w-xl mx-auto text-center">
          <h2 class="text-2xl font-bold tracking-wieder"><span class="block font-extrabold text-brand-orange">Vielen Dank</span>Deine
            Anfrage wurde erfolgreich an uns übermittelt.</h2>
          <p class="mt-2 text-md">{{ successMsg }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../classes/FormClass';

export default {
  name: 'ContactForm',
  data() {
    return {
      domainObject : {},
      form         : new Form({
        firstName              : '',
        lastName               : '',
        subject                : '',
        message                : '',
        salutation             : 'please_select',
        phoneNr                : '',
        contact_type           : 'please_select',
        company_name           : '',
        email                  : '',
        data_privacy           : null,
        newsletter_subscription: false,
      }),
      success      : false,
      errors       : {},
      msg          : '',
      customStatus : '',
      loaderSpinner: false,
    };
  },
  mounted() {
    // console.log(this.errors.first_name !== null ? 'invalid' : '');
  },
  computed: {
    isInvalid() {
      return 'invalid';
    },
  },
  methods : {
    saveContactData() {
      this.loaderSpinner = true;

      this.form.post('contact-form').then((data) => {
        this.success    = true;
        this.successMsg = data.data.text;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaderSpinner = false;
      });
    },
    selectNewsletterToggle() {
      return (this.form.newsletter_subscription =
          !this.form.newsletter_subscription);
    },
  },
};
</script>

<style scoped>

</style>
