import Errors from "./ErrorClass";
import Success from "./SuccessClass";

export default class Form {
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];
        }

        this.success = new Success();
        this.errors = new Errors();
    }

    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    set(data) {
        for (let field in data) {
            this[field] = data[field];
        }
    }

    setFormData(data) {
        let formData = new FormData();
        for (let field in data) {
            formData.append(field, data[field]);
        }
        return formData;
    }

    reset() {
        for (let field in this.originalData) {
            this[field] = this.originalData[field];
        }

        this.errors.clear();
    }

    post(url, resetForm = false) {
        return this.submit("post", url, resetForm);
    }

    put(url, resetForm = false) {
        return this.submit("put", url, resetForm);
    }

    delete(url, resetForm = false) {
        return this.submit("delete", url, resetForm);
    }

    get(url) {
        return this.submit("get", url);
    }

    postWithFile(url, resetForm = false) {
        return this.submitFiles("post", url, resetForm);
    }

    submit(requestType, url, resetForm) {
        return new Promise((resolve, reject) => {
            this.errors.clear();
            this.success.clear();

            axios
                .get("/sanctum/csrf-cookie", {
                    Accept: "application/json",
                })
                .then((response) => {
                    if (response.status === 204) {
                        axios[requestType](url, this.data())
                            .then((response) => {
                                this.onSuccess(response.data, resetForm);
                                resolve(response.data);
                            })
                            .catch((error) => {
                                this.onFail(error.response.data.errorMessage);
                                reject(error.response.data.errorMessage);
                            });
                    }
                })
                .catch((error) => {
                    this.onFail(error.response.data);
                    reject(error.response.data);
                });
        });
    }

    submitFiles(requestType, url, resetForm) {
        return new Promise((resolve, reject) => {
            this.errors.clear();
            this.success.clear();

            axios
                .get("/sanctum/csrf-cookie", {
                    Accept: "application/json",
                })
                .then((response) => {
                    if (response.status === 204) {
                        axios[requestType](url, this.setFormData(this.data()))
                            .then((response) => {
                                this.onSuccess(response.data, resetForm);
                                resolve(response.data);
                            })
                            .catch((error) => {
                                this.onFail(error.response.data.errorMessage);
                                reject(error.response.data.errorMessage);
                            });
                    }
                })
                .catch((error) => {
                    this.onFail(error.response.data);
                    reject(error.response.data);
                });
        });
    }

    onSuccess(response, resetForm = false) {
        this.success.record(response.data);
        this.reset();
    }

    onFail(errors) {
        this.errors.record(errors);
    }
}
