<template>
  <div v-if="showModal === true">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
          <div>
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 64 64" stroke="currentColor"
                   aria-hidden="true" class="h-8 w-8 text-red-600">
                <g>
                  <path d="M61.1,42L38.9,11.8c-1.6-2.2-4.2-3.5-6.9-3.5c-2.8,0-5.3,1.3-6.9,3.5L2.9,42c-1.9,2.6-2.2,6.1-0.7,9
                        c1.5,2.9,4.4,4.7,7.7,4.7h44.3c3.3,0,6.2-1.8,7.7-4.7S63,44.6,61.1,42z M58.7,49.4c-0.9,1.7-2.6,2.8-4.5,2.8H9.9
                        c-2,0-3.7-1-4.5-2.8c-0.9-1.7-0.7-3.7,0.4-5.3l22.1-30.2c1-1.3,2.5-2.1,4.1-2.1c1.6,0,3.1,0.8,4.1,2.1l22.1,30.2
                        C59.4,45.7,59.6,47.6,58.7,49.4z"/>
                  <path
                      d="M32,38c1,0,1.8-0.8,1.8-1.8V25c0-1-0.8-1.8-1.8-1.8S30.2,24,30.2,25v11.3C30.2,37.2,31,38,32,38z"/>
                  <path
                      d="M32,41.3c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9C34.9,42.6,33.6,41.3,32,41.3z"/>
                </g>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-lg leading-6 font-medium" id="modal-title">Die Session ist abgelaufen</h3>
              <div class="mt-2">
                <p class="text-sm">Die Seite wird in <span class="font-bold" v-if="refreshTimerOutput !== null">{{
                    refreshTimerOutput
                  }}</span><span class="font-bold" v-else>{{ minutesStart }} Min {{ secondsStart }} Sek</span>
                  aktualisieren. Wenn Du
                  nicht warten möchtest, dann klicke auf "Seite aktualisieren".</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <button type="button" @click="reloadPage"
                    class="flex self-start w-full py-3 px-6 font-bold text-center text-white rounded-sm md:max-w-xl md:w-auto mx-auto text-lg bg-brand-orange hover:bg-brand-orange-dark hover:cursor-pointer focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 focus-visible:rounded-sm focus:outline-none">
              Seite aktualisieren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'AutoReloadModal',
  props: [],
  data() {
    return {
      events            : ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      showModalTimer    : null,
      refreshTimer      : 120,
      refreshTimerOutput: null,
      showModal         : false,
      activePAge        : null,
      minutesStart      : '02',
      secondsStart      : '00'
    };
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
    this.activePage = window.location.pathname;
  },
  methods: {
    setTimers() {
      //this.warningTimer = setTimeout(this.warningMessage, 1100 * 1000);
      if (this.activePage !== '/') {
        this.showModalTimer = setTimeout(this.openModal, 1200 * 1000);
      }
    },
    openModal() {
      this.showModal = !this.showModal;

      if (this.showModal === true) {
        this.refreshPageTimer();
      }
    },
    resetTimer() {
      clearTimeout(this.showModalTimer);

      this.setTimers();
    },
    refreshPageTimer() {
      const refreshInterval = setInterval(() => {
        this.refreshTimer--;

        const minutes = (Math.floor(((this.refreshTimer % 3600) / 60)) < 10) ? '0' + Math.floor(((this.refreshTimer % 3600) / 60)) : Math.floor(((this.refreshTimer % 3600) / 60));
        const seconds = Math.floor(this.refreshTimer - minutes * 60) < 10 ? '0' + Math.floor(this.refreshTimer - minutes * 60) : Math.floor(this.refreshTimer - minutes * 60);

        this.refreshTimerOutput = minutes + ' Min' + ' ' + seconds + ' Sek';

        if (this.refreshTimer === 0) {
          clearInterval(refreshInterval);
          this.refreshTimer = 10;
          this.showModal = false;
          this.refreshTimerOutput = null;
          window.location.reload(true);
        }
      }, 1000);
    },
    reloadPage() {
      window.location.reload(true);
    }
  }
};
</script>

<style scoped>

</style>
