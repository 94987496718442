<template>
    <div>
      <div v-if="!success" class="text-lg max-w-screen-lg mx-auto">
        <h1><span
            class="block text-3xl text-center leading-8 font-extrabold tracking-wieder sm:text-4xl">Registrieren</span>
        </h1>
        <p class="mt-8 text-md text-gray-500 leading-8"></p>
      </div>
      <div class="mt-6 max-w-screen-lg mx-auto">
        <div id="form" v-if="!success">
          <div class="space-y-10">
            <div class="bg-white px-4 py-5 shadow sm:p-6">
              <div class="md:grid md:grid-cols-1 md:gap-6">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-bold leading-6 text-gray-900">Personenbezogene Daten</h3>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="mt-6 grid grid-cols-12 gap-6 md:mt-0">
                    <div class="relative col-span-12 sm:col-span-6">
                      <label for="contact_type" class="block text-sm font-bold text-black"
                             :class="{'text-red-500':form.errors.has('contact_type'),'text-black': '',}">Kundentyp<span
                          class="text-red-600">*</span></label>
                      <div class="relative flex items-center">
                        <select
                            id="contact_type"
                            name="contact_type"
                            autocomplete="contact_type"
                            class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                            :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-900 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('contact_type')}"
                            v-model="form.contact_type">
                          <option value="please_select">Bitte wählen</option>
                          <option value="business">Business</option>
                          <option value="private">Privat</option>
                        </select>
                      </div>
                      <span
                          role="alert"
                          class="mt-2 text-sm text-red-500"
                          v-if="form.errors.has('contact_type')"
                          v-text="form.errors.get('contact_type')"
                      ></span>
                    </div>
                    <div class="relative col-span-12 sm:col-span-6" v-if="form.contact_type === 'business'">
                      <label
                          for="company_name"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500':form.errors.has('company_name'),'text-black': '',}">Firmennamen<span class="text-red-600">*</span></label>
                      <div class="right-0 flex w-full flex-col">
                        <div class="relative flex items-center">
                          <input
                              type="text"
                              name="company_name"
                              id="company_name"
                              placeholder="Firmennamen"
                              class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                              :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-900 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('company_name'),}"
                              v-model="form.company_name"/>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('company_name')"
                            v-text="form.errors.get('company_name')"></span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 grid grid-cols-12 gap-6">
                    <div class="relative col-span-12 sm:col-span-6">
                      <label for="salutation" class="block text-sm font-bold text-black"
                             :class="{'text-red-500':form.errors.has('salutation'),'text-black': '',}">Anrede<span class="text-red-600">*</span></label>
                      <div class="relative flex items-center">
                        <select
                            id="salutation"
                            name="salutation"
                            autocomplete="salutation"
                            class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                            :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('salutation')}"
                            v-model="form.salutation">
                          <option value="please_select">
                            Bitte wählen
                          </option>
                          <option value="female">Frau</option>
                          <option value="male">Herr</option>
                        </select>
                      </div>
                      <span
                          role="alert"
                          class="mt-2 text-sm text-red-500"
                          v-if="form.errors.has('salutation')"
                          v-text="form.errors.get('salutation')"
                      ></span>
                    </div>
                  </div>
                  <div class="mt-6 grid grid-cols-12 gap-6">
                    <div class="relative col-span-12 sm:col-span-6">
                      <label
                          for="first_name"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500':form.errors.has('first_name'),'text-black': ''}">Vorname<span class="text-red-600">*</span></label>
                      <div class="relative right-0 flex w-full flex-col">
                        <div class="relative flex items-center">
                          <input
                              type="text"
                              name="first_name"
                              id="first_name"
                              autocomplete="given-name"
                              placeholder="Vorname"
                              class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                              :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('first_name')}"
                              v-model="form.first_name"/>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('first_name')"
                            v-text="form.errors.get('first_name')"></span>
                      </div>
                    </div>
                    <div class="relative col-span-12 sm:col-span-6">
                      <label
                          for="last_name"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500':form.errors.has('last_name'),'text-black': '',}">Nachname<span class="text-red-600">*</span></label>
                      <div class="relative right-0 flex w-full flex-col">
                        <div class="relative flex items-center">
                          <input
                              type="text"
                              name="last_name"
                              id="last_name"
                              autocomplete="given-name"
                              placeholder="Nachname"
                              class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                              :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm': form.errors.has('last_name')}"
                              v-model="form.last_name"/>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('last_name')"
                            v-text="form.errors.get('last_name')"></span>
                      </div>
                    </div>
                  </div>
                  <div class="relative col-span-12 mt-6 sm:col-span-12">
                    <label for="phone_nr" class="block text-sm font-bold text-black"
                           :class="{'text-red-500':form.errors.has('phone_nr'),'text-black': '',}">Telefon Nr.<span class="text-red-600">*</span></label>
                    <div class="relative right-0 flex w-full flex-col">
                      <div class="relative flex items-center">
                        <input
                            type="text"
                            name="phone_nr"
                            id="phone_nr"
                            autocomplete="given-phone-nr"
                            class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                            :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('phone_nr')}"
                            v-model="form.phone_nr" v-mask="'\+\XX-XX-XXXXXXXXXX'"
                            :placeholder="'4158975588897'|VMask('\+\XX-XX-XXXXXXXXXX')"/>
                      </div>
                      <span
                          role="alert"
                          class="mt-2 text-sm text-red-500"
                          v-if="form.errors.has('phone_nr')"
                          v-text="form.errors.get('phone_nr')"
                      ></span>
                    </div>
                  </div>
                  <div class="relative col-span-12 mt-6 sm:col-span-12">
                    <label
                        for="email"
                        class="block text-sm font-bold text-black"
                        :class="{'text-red-500':form.errors.has('email'),'text-black': ''}">E-Mail-Adresse<span class="text-red-600">*</span></label>
                    <div class="relative right-0 flex w-full flex-col">
                      <div class="relative flex items-center">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            autocomplete="given-email"
                            placeholder="E-Mail-Adresse"
                            class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                            :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('email')}"
                            v-model="form.email"/>
                      </div>
                      <span
                          role="alert"
                          class="mt-2 text-sm text-red-500"
                          v-if="form.errors.has('email')"
                          v-text="form.errors.get('email')"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white px-4 py-5 shadow sm:p-6">
              <div class="md:grid md:grid-cols-1 md:gap-6">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-bold leading-6 text-gray-900">Anschrift</h3>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="col-span-2 grid grid-cols-12 gap-6">
                    <div class="relative col-span-12 sm:col-span-9">
                      <label
                          for="street_name"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500': form.errors.has('street_name'), 'text-black': ''}">Strasse<span class="text-red-600">*</span></label>
                      <div class="right-0 flex w-full flex-col">
                        <div class="relative flex items-center">
                          <input
                              type="text"
                              name="street_name"
                              id="street_name"
                              autocomplete="given-name"
                              placeholder="Strasse"
                              class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                              :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('street_name')}"
                              v-model="form.street_name"/>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('street_name')"
                            v-text="form.errors.get('street_name')"
                        ></span>
                      </div>
                    </div>
                    <div class="relative col-span-12 sm:col-span-3">
                      <label
                          for="house_number"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500':form.errors.has('street_name'),'text-black': ''}">Haus Nr.<span class="text-red-600">*</span></label>
                      <div class="right-0 flex w-full flex-col">
                        <div class="relative flex items-center">
                          <input
                              type="text"
                              name="house_number"
                              id="house_number"
                              autocomplete="given-house-nr"
                              placeholder="Haus Nr."
                              class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                              :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('street_name')}"
                              v-model="form.house_number"/>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('house_number')"
                            v-text="form.errors.get('house_number')"></span>
                      </div>
                    </div>
                    <div class="relative col-span-12 sm:col-span-3">
                      <label
                          for="postal_code"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500':form.errors.has('postal_code'),'text-black': '',}">PLZ<span class="text-red-600">*</span></label>
                      <div class="right-0 flex w-full flex-col">
                        <div class="relative flex items-center">
                          <input
                              type="text"
                              name="postal_code"
                              id="postal_code"
                              autocomplete="given-postal-code"
                              placeholder="PLZ"
                              class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                              :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('postal_code'),}"
                              v-model="form.postal_code"/>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('postal_code')"
                            v-text="form.errors.get('postal_code')"></span>
                      </div>
                    </div>
                    <div class="relative col-span-12 sm:col-span-9">
                      <label
                          for="city"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500':form.errors.has('city'),'text-black': ''}">Ort<span class="text-red-600">*</span></label>
                      <div class="right-0 flex w-full flex-col">
                        <div class="relative flex items-center">
                          <input
                              type="text"
                              name="city"
                              id="city"
                              autocomplete="given-name"
                              placeholder="Ort"
                              class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                              :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('city')}"
                              v-model="form.city"/>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('city')"
                            v-text="form.errors.get('city')"
                        ></span>
                      </div>
                    </div>
                    <div class="relative col-span-12">
                      <label
                          for="country"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500':form.errors.has('country'),'text-black': ''}">Land<span class="text-red-600">*</span></label>
                      <div class="relative flex items-center">
                        <select
                            id="country"
                            name="country"
                            class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                            :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('country')}"
                            v-model="form.country">
                          <option value="please_select">
                            Bitte wählen
                          </option>
                          <option value="ch">Schweiz</option>
                          <option value="de">
                            Deutschland
                          </option>
                          <option value="at">
                            Österreich
                          </option>
                        </select>
                      </div>
                      <span
                          role="alert"
                          class="mt-2 text-sm text-red-500"
                          v-if="form.errors.has('country')"
                          v-text="form.errors.get('country')"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white px-4 py-5 shadow sm:p-6">
              <div class="md:grid md:grid-cols-1 md:gap-6">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-bold leading-6 text-gray-900">Produktspezifische Informationen</h3>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="col-span-1 grid grid-cols-12 gap-6">
                    <div class="relative col-span-12">
                      <label
                          for="domain.name"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500': form.errors.has('domain.name'),'text-black': '',}">Domainnamen
                        überprüfen<span class="text-red-600">*</span></label>
                      <div class="right-0 flex flex-col">
                        <div class="relative flex items-center">
                          <div class="grid w-full grid-flow-col grid-cols-2 gap-2">
                            <div class="col-span-2">
                              <input
                                  type="text"
                                  name="domain.name"
                                  id="domain.name"
                                  autocomplete="given-name"
                                  placeholder="zB. wdigital.host"
                                  class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                                  :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('domain.name')}"
                                  v-model="form.domain.name"/>
                            </div>
                            <div class="col-span-2">
                              <button @click="requestIsDomainFree"
                                      :disabled="form.domain.name === null || form.domain.name === '' || form.domain.name.length <= 2"
                                      class="flex self-start items-center w-full py-2 px-5 font-bold text-center text-white rounded-sm md:max-w-xs md:w-auto text-base bg-brand-orange hover:bg-brand-orange-dark hover:cursor-pointer whitespace-nowrap focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 focus-visible:rounded-sm focus:outline-none"
                                      :class="{ 'opacity-25 cursor-not-allowed hover:bg-brand-orange': form.domain.name === null || form.domain.name === '' || form.domain.name.length <= 2 }">
                              <span class="flex items-center"><span v-if="this.domainSearchActive"><svg
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                  class="animate-spin h-5 w-5 text-white mr-2"><circle
                                  cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"
                                  class="opacity-25"></circle><path fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                    class="opacity-75"></path></svg></span><span>Verfügbarkeit Abfrage</span></span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3 bg-green-50 p-4" v-if="domain.status === 'available'">
                          <p class="text-green-700">
                            <strong>Glückwunsch!</strong>
                            deine Wunschdomain ist noch!
                          </p>
                        </div>
                        <div
                            class="mt-3 bg-red-50 p-4"
                            v-else-if="domain.status !== null && domain.status !== 'available'">
                          <p class="text-red-700">
                            <strong>Domain besetzt</strong>
                            - zu WDigital transferieren?
                          </p>
                          <div class="mt-2 text-sm text-red-700">
                            <p>Wenn dieser Domainname Dir gehört, dann Empfehlen wir Dir, auch mit dem Domainnamen zu
                              wechseln.
                            </p>
                          </div>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('domain.name')"
                            v-text="form.errors.get('domain.name')"
                        ></span>
                      </div>
                    </div>
                    <div v-if="domain.status === 'assigned'" class="relative col-span-12">
                      <label
                          for="domain.transfer_code"
                          class="block text-sm font-bold text-black"
                          :class="{'text-red-500': form.errors.has('domain.transfer_code'),'text-black': '',}">Transfer-Auth-Code<span class="text-red-600">*</span></label>
                      <div class="right-0 flex flex-col">
                        <div class="relative flex items-center">
                          <div class="grid w-full grid-flow-col grid-cols-2 gap-2">
                            <div class="col-span-2">
                              <input
                                  type="text"
                                  name="domain.transfer_code"
                                  id="domain.transfer_code"
                                  autocomplete="given-name"
                                  placeholder="Transfer-Auth-Code eingeben ..."
                                  class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                                  :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('domain.transfer_code')}"
                                  v-model="form.domain.transfer_code"
                              />
                            </div>
                          </div>
                        </div>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-gray-500"
                            v-if="!form.errors.has('domain.transfer_code')">Den Transfer-Auth-Code findest Du bei deinem aktuellen Hosting-Anbieter.</span>
                        <span
                            role="alert"
                            class="mt-2 text-sm text-red-500"
                            v-if="form.errors.has('domain.transfer_code')"
                            v-text="form.errors.get('domain.transfer_code')"
                        ></span>
                      </div>
                    </div>
                    <div class="relative col-span-12">
                      <label for="subscription" class="block text-sm font-bold text-black"
                             :class="{'text-red-500': form.errors.has('subscription'),'text-black': '',}">Gewünschte Abonnement<span class="text-red-600">*</span></label>
                      <div class="relative flex items-center">
                        <select
                            id="subscription"
                            name="subscription"
                            class="block w-full border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange sm:text-sm"
                            :class="{'focus:outline-none border-red-300 text-red-900 placeholder-red-500 ring-1 ring-red-500 focus:border-brand-orange focus:ring-brand-orange focus:ring-red-500 disabled:opacity-90 sm:text-sm':form.errors.has('subscription'),}"
                            v-model="form.subscription">
                          <option value="please_select">Bitte wählen</option>
                          <option :value="product.article_code" v-for="(product,index) in products" :key="index">
                            {{ product.display }}
                          </option>
                        </select>
                      </div>
                      <span
                          role="alert"
                          class="mt-2 text-sm text-red-500"
                          v-if="form.errors.has('subscription')"
                          v-text="form.errors.get('subscription')"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white px-4 py-5 shadow sm:p-6">
              <div class="md:grid md:grid-cols-1 md:gap-6">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-bold leading-6 text-gray-900">Benachrichtigungen</h3>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="col-span-2 grid grid-cols-12 divide-y divide-gray-200">
                    <div class="relative col-span-full pb-4">
                      <div class="grid-cols-full grid"><span
                          class="text-base font-bold text-gray-900">E-Mail Marketing</span>
                        <ul class="mt-4">
                          <li>
                            <div class="flex items-center justify-between">
                                                  <span class="flex flex-grow flex-col">
                                                      <span class="block text-sm font-bold text-black"
                                                            id="availability-label">WDigital Newsletter abonnieren</span>
                                                      <span class="text-sm text-gray-500 md:w-3/4">Erhalte regelmässig informationen zu unseren Dienstleistungen, Produkte, Tipps & Tricks zu verschiedenen Themen. Und bleibe so immer auf dem aktuellsten Stand.</span>
                                                  </span>
                              <button type="button" @click="selectNewsletterToggle"
                                      class="focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-brand-orange focus:ring-offset-2"
                                      :class="{'bg-brand-orange':form.newsletter_subscription === true, 'bg-gray-200': '',}"
                                      role="switch"><span aria-hidden="true"
                                                          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                                          :class="{'translate-x-5':form.newsletter_subscription ===true,'translate-x-0':'',}"></span>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative col-span-full bg-white px-4 py-5 shadow sm:p-6">
              <div class="right-0 flex w-full flex-col">
                <div class="relative flex">
                  <input
                      type="checkbox"
                      name="data_privacy"
                      id="data_privacy"
                      class="focus:outline-none mt-1 block h-5 w-5 border border-gray-300 shadow-sm focus:border-brand-orange focus:ring-brand-orange"
                      v-model="form.data_privacy"
                  />
                  <label
                      for="data_privacy"
                      class="prose-blog ml-2 block text-xs font-medium text-black"
                      :class="{'text-red-500':form.errors.has('data_privacy'),'text-black': ''}">Hiermit bestätige ich,
                    dass ich die
                    <a class="link" href="/agb" target="_blank">AGB</a> und <a class="link" href="/datenschutz"
                                                                               target="blank">Datenschutzerklärung</a>
                    von Würtenberger Digital gelesen habe und mit der Geltung einverstanden bin.<span><span class="text-red-600">*</span></span></label>
                </div>
                <span
                    role="alert"
                    class="ml-7 text-sm text-red-500"
                    v-if="form.errors.has('data_privacy')"
                    v-text="form.errors.get('data_privacy')"
                ></span
                >
              </div>
            </div>
            <div class="flex justify-end">
              <button
                  type="button"
                  @click="sendForm"
                  class="flex self-start items-center py-2 px-5 font-bold text-center text-white rounded-sm stageLink md:max-w-xs md:w-auto text-base bg-brand-orange hover:bg-brand-orange-dark hover:cursor-pointer whitespace-nowrap focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 focus-visible:rounded-sm focus:outline-none">
                <span v-if="this.requestSend"><svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    class="animate-spin h-5 w-5 text-white mr-2"><circle
                    cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"
                    class="opacity-25"></circle><path fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                      class="opacity-75"></path></svg></span><span>Jetzt registrieren</span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="success" class="flex flex-col items-center">
          <div
              class="bg-brand-orange p-3 text-white text-7xl h-36 w-36 items-center justify-center flex rounded rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 64 64" stroke="currentColor"
                 aria-hidden="true" class="w-20 w-20 text-white mr-2">
              <path d="M61.7,13.3c-0.4-0.4-1-0.4-1.4,0L23.8,48.6c-0.6,0.5-1.5,0.5-2.1,0L3.7,31.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4
	l18.1,17.6c0.7,0.6,1.5,1,2.4,1c0.9,0,1.8-0.3,2.4-1l36.5-35.4C62.1,14.3,62.1,13.6,61.7,13.3z"/>
            </svg>
          </div>
          <div class="mt-10 max-w-xl mx-auto text-center">
            <h2 class="text-2xl font-bold tracking-wieder"><span
                class="block font-extrabold text-brand-orange">Vielen Dank</span>Deine Registrierung wurde erfolgreich
              an uns
              übermittelt.</h2>
            <p class="mt-2 text-md">{{ successMsg }}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Form from '../classes/FormClass';

export default {
    name: "RegisterForm",
    props: ["affiliateIdentifier"],
    data() {
        return {
            form: new Form({
              contact_type           : 'please_select',
              company_name           : null,
              salutation             : 'please_select',
              first_name             : null,
              last_name              : null,
              phone_nr               : null,
              email                  : null,
              street_name            : null,
              house_number           : null,
              postal_code            : null,
              city                   : null,
              country                : 'please_select',
              data_privacy           : false,
              newsletter_subscription: false,
              subscription           : 'please_select',
              domain                 : {
                status       : null,
                name         : null,
                transfer_code: null
              },
              affiliate_id           : this.affiliateIdentifier ?? null,
              errorSession: null
            }),
          domain: {
            status: null
          },
          domainSearchActive: false,
          products: [],
          success: false,
          successMsg: null,
          requestSend: false
        };
    },
  mounted() {
    this.getProduct();
  },
  computed: {
    textBrandName() {
      return '<span class="text-brand-blue font-bold">WDigital<span class="text-brand-orange">.</span></span>';
    }
  },
  methods : {
    getProduct() {
      axios.get('products/').then((data) => {
        this.products = data.data.products;
      });
    },
    selectPrivacyToggle() {
      return (this.form.data_privacy = !this.form.data_privacy);
    },
    selectNewsletterToggle() {
      return (this.form.newsletter_subscription =
          !this.form.newsletter_subscription);
    },
    requestIsDomainFree() {
      this.domainSearchActive = true;
      axios.get('check-domain/' + this.form.domain.name).then((data) => {
        this.domain.status = data.data.initial[0].status;
        this.form.domain.status = data.data.initial[0].status;
        this.domainSearchActive = false;
      }).finally(() => {
        this.domainSearchActive = false;
      });
    },
    sendForm() {
      this.requestSend = true;

      this.form.post('auth/register').then((data) => {
        this.success = true;
        this.successMsg = data.data.text;
      }).catch((error) => {
        window.scrollTo();
      }).finally(() => {
        this.requestSend = false;
      });
    }
  }
};
</script>

<style scoped></style>
