<template>
    <div>
        <div class="relative col-span-12 sm:col-span-3 sm:flex sm:items-center">
            <div class="w-full">
                <label
                    for="domianname"
                    class="block text-sm font-medium text-gray-700"
                    >Domainnamen überprüfen</label
                >
                <div class="mt-1 grid w-full grid-flow-col grid-cols-2 gap-2">
                    <div class="col-span-2">
                        <input
                            type="text"
                            name="domianname"
                            id="domianname"
                            autocomplete="given-name"
                            placeholder="Domainnamen eingeben ..."
                            class="block w-full border border-gray-300 py-2 px-3 shadow-sm sm:text-sm"
                            v-model="inputDomainname"
                        />
                    </div>
                    <div class="col-span-2">
                        <button
                            type="button"
                            @click="requestIsDomainFree"
                            class="hover:bg-brand-blue-dark-900 focus:outline-none flex items-center justify-center border border-transparent bg-brand-blue py-2 px-3 text-sm font-bold text-white transition duration-150 ease-in-out focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Verfügbarkeit Abfrage
                        </button>
                    </div>
                </div>
                <span role="alert" class="mt-2 text-sm text-red-500"
                    >Hier steht eine Fehlermeldung</span
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckDomainname",
    props: [],
    data() {
        return {
            inputDomainname: null,
        };
    },
    methods: {
        requestIsDomainFree() {
            console.log("Bitte warten ... Domain wird gerade abgefragt...");
        },
    },
};
</script>

<style scoped></style>
