export default class Errors {
    constructor() {
        this.success = {};
    }

    any() {
        return Object.keys(this.success).length > 0;
    }

    record(success) {
        this.success = success;
    }

    clear(field) {
        if (field) {
            delete this.success[field];

            return;
        }

        this.success = {};
    }
}
